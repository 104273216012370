<template>
	<div>
		<b-modal ref="modal" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("monte.reserver_semence") }}
				</template>
			</template>

			<div>
				<form @submit.prevent="checkForm" autocomplete="off">	
					<div class="row">
						<div class="col-12">
							<div class="form-group">
								<label>{{ $t('monte.reservation.date') }}</label>
								<e-datepicker
									v-model="delivery_date"
								/>
							</div>
						</div>

						<div class="col-12">
							<div class="form-group">
								<label>{{ $t('monte.reservation.commentaire') }}</label>
								<b-form-textarea
									id="form_comment"
									v-model="comment"
									:placeholder="$t('monte.reservation.saisir_commentaire')"
									rows="3"
									max-rows="6"
								></b-form-textarea>
							</div>
						</div>
					</div>
				</form>

				<div class="text-center">
					<b-button variant="primary" @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("monte.reserver") }} <font-awesome-icon :icon="['fal', 'check']"/></b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Expeditions from '@/mixins/Expeditions.js'

	export default {
		name: "ReservationModal",
		mixins: [Expeditions],
		data () {
			return {
				processing: false,
				contract: null,
				delivery_date: null,
				comment: ''
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
			},
			openModal(contract) {
				this.contract = contract
				this.$refs['modal'].show()
			},
			async checkForm() {
				const params = {
					delivery_date	: this.delivery_date,
					commentaire 	: this.comment
				}

				await this.createReservation(this.contract.contract_id, params)
				this.successToast('monte.reservation.done')
				this.contract = false
				this.delivery_date = null
				this.comment = ''
				this.$refs['modal'].hide()
			}
        },
	}
</script>